import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';

import GallerySlider from '../components/Fundraiser/GallerySlider';
import DoacaoSorteioModal from "../components/Modal/DoacaoSorteioModal";
import DoacaoConsultaModal from "../components/Modal/DoacaoConsultaModal";

import CountUp from "react-countup";
import {IMAGES} from "../constant/theme";

import FoundCounter from "../components/Widget/FoundCounter";

import cat from "../assets/images/ic_assets/animals/gato.png";
import dog from "../assets/images/ic_assets/animals/cachorro.png";

import HondaCity from '../assets/images/prizes/Novo-Honda-City-2018-6-150px.jpeg';

import DogBanner from './../assets/images/prizes/dog-banner.png';

import axios from "axios";

const FundraiserDetail = () => {

    const formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const [list, setList] = React.useState(
        {id: 1, raised: 0, goal: 0, supporters: 0, daysLeft: 0},
    );

    React.useEffect(() => {
        getDetails();
    }, []);

    function getDetails() {

    }

    const percentGoal = (raised, goal) => {
        return (raised / goal) * 100;
    };

    let {raised, goal, supporters, daysLeft} = list;
    const percentage = percentGoal(raised, goal);

    // raised = formater.format(raised);
    goal = formater.format(goal);

    const modalRef = useRef(null);

    const modalConsultRef = useRef(null);

    return (
        <>
            <main>
                <section id="sorteio" className="featured bg-secondary">
                    <div className="overlay"></div>
                    <div className="container d-flex align-items-center">
                        <div className="row row-cols-md-2 ">
                            <div className="px-5">
                                <h5 className="subtitle text-primary">DOE E AJUDE</h5>
                                <h1>Sorteio pelos Animais</h1>
                                <p>
                                    Junte-se a nós nesta campanha incrível em prol dos animais! Ao adquirir um número do
                                    Sorteio você não apenas concorre a um prêmio espetacular, mas também se junta a uma
                                    causa nobre e essencial para a proteção e cuidado de animais resgatados.
                                </p>
                                <div className="prize-square">
                                    <img src={HondaCity}/>
                                    <p style={{fontSize: '11px'}}>Foto meramente ilustrativa. Consulte o regulamento.</p>
                                </div>
                            </div>
                            <div>
                                <div className="featured-cta mx-md-5">
                                    <img src={DogBanner}/>
                                    <h2>Concorra a 1 <b className="text-primary">HONDA/CITY LX CVT 2018, CINZA.</b></h2>
                                    <p><b>A cada R$ 30,00 doados, uma chance de ganhar!</b></p>
                                    <button className="btn btn-md btn-primary my-3 p-3"
                                            id="doar"
                                            onClick={() => {
                                                modalRef.current.handleModalOpen();
                                            }}>
                                    <i className="lab la-gratipay"></i>ADQUIRA
                                        SEU NÚMERO
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="" className="aos-item cta-wrapper" data-aos="fade-up">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-4 cta-bar">
                            <div className="col">
                                <h5 className="subtitle text-primary">Valor por número:</h5>
                                <hr className="divider"/>
                                <h4>R$ 30,00</h4>
                            </div>
                            <div className="col">
                                <h5 className="subtitle text-primary">Data do sorteio:</h5>
                                <hr className="divider"/>
                                <h4>21/09/2024</h4>
                            </div>
                            <div className="col">
                                <h5 className="subtitle text-primary">Mais Informações</h5>
                                <hr className="divider"/>
                                <a href="https://cdn.ajudeumacausa.com.br/termos/Certificado_autorizacao_sorteio+pelos+animais+-+SETEMBRO-+ok+010330182024.pdf" target="_blank">Certificado</a><br/>
                                <a href="https://cdn.ajudeumacausa.com.br/termos/Regulamento_SORTEIO+PELOS+ANIMAIS+-+SETEMBRO+OK++-+0202400870.pdf" target="_blank">Regulamento</a>
                            </div>
                            <div className="col bg-primary">
                                <h5 className="subtitle text-black">CONSULTE SEUS NÚMEROS</h5>
                                <hr className="divider"/>
                                <button className="btn btn-sm btn-dark p-3" data-bs-toggle="modal"
                                        id={"consultarNumero"}
                                        onClick={() => {
                                            modalConsultRef.current.handleModalOpen();
                                        }}
                                        data-bs-target="#consultarModal"><i className="las la-search"></i> CONSULTAR
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sobre" className="py-5 aos-item" data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h5 className="subtitle text-primary">SOBRE</h5>
                                <h2>Instituto Caramelo</h2>
                                <hr className="divider"/>
                                <p>Fundado em fevereiro de 2015, a partir da união de um grupo de pessoas em prol do
                                    propósito de cuidar bem e adotar bem cada animal, o Instituto Caramelo atua
                                    principalmente no resgate de animais feridos ou em situação de risco, recuperação e
                                    adoção. Mantemos um abrigo com cerca de 300 animais, entre cães e gatos, todos
                                    resgatados das ruas, onde eles são protegidos, tratados, alimentados e aguardam pela
                                    chance de serem adotados.</p>

                                <p>O Instituto Caramelo fica em Ribeirão Pires, na região metropolitana, em um terreno
                                    de 27.000 metros quadrados. Há centro cirúrgico com atendimento veterinário 24
                                    horas, núcleo de fisioterapia, adestramento para tratamento psicológico, 5 áreas de
                                    soltura, 35 canis, 4 gatis e uma equipe de mais de 40 profissionais que se doam por
                                    completo para que o abrigo seja apenas uma fase de passagem na vida dos bichos. São
                                    necessários cerca de 300 mil reais por mês para sustentá-lo e são as doações,
                                    parcerias e vendas na loja que tornam esse sonho possível.</p>

                                <a href="https://institutocaramelo.org/" className="btn btn-md btn-dark"
                                   target="_blank">Saiba mais</a>

                            </div>
                            <div className="col-12 col-md-6 px-md-5">
                                <img src={IMAGES.About00} className="rounded w-100 m-md-3"/>
                                <div className="row">
                                    <div className="col">
                                        <img src={IMAGES.About03} className="rounded w-100 m-md-3"/>

                                    </div>
                                    <div className="col">
                                        <img src={IMAGES.About04} className="rounded w-100 m-md-3"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <DoacaoSorteioModal
                ref={modalRef}
            ></DoacaoSorteioModal>
            <DoacaoConsultaModal ref={modalConsultRef}>
            </DoacaoConsultaModal>
        </>
    )
}

export default FundraiserDetail;
